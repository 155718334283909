<template>
  <div :class="!topBarShow ? 'winningList-container active' : 'winningList-container'
    ">
    <van-nav-bar v-if="topBarShow" @click-left="goBack" left-arrow title="全部商品" :border="false" />
    <div class="goodsList">
      <div class="goodsOne" v-for="(item, index) in winningList" :key="index">
        <img :src="item.thumb" alt="">
        <div class="goodsRight">
          <div class="rightTop">
            <div class="jiang">{{ item.prize_title }}</div>
            <div class="jiangTitle">{{ item.goods_title }}</div>
          </div>
          <div class="fenshu">奖品份数：{{ item.quantity }}份</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { NavBar, Toast } from "vant";
import { useStore } from "vuex";
import { isWeiChat } from "@/utils/environment";
import Api from "@/API";
export default {
  name: "winningList",
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {
    //中奖记录列表
    let winningList = ref([]);
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    //创建API对象
    const API = new Api();
    // 顶部栏显示隐藏
    const topBarShow = !isWeiChat();
    //返回
    function goBack() {
      router.back();
    }
    //初始化函数
    function init() {
      getWinningRecord();
    }
    //获取中奖记录
    const getWinningRecord = async () => {
      let res = await API.getBlindBoxWinningRecord({
        token: store.state.Token,
        luck_id: store.state.Luckid,
      });
      winningList.value = res.data;
    };
    onMounted(() => {
      init();
      console.log(store.state.Token)
    });
    return {
      winningList,
      goBack,
      topBarShow,
    };
  },
};
</script>
  
<style lang="scss">
:root {
  --van-nav-bar-icon-color: #000;
}

.winningList-container.active {
  .van-nav-bar {
    padding-top: 0;
  }
}

.winningList-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .van-nav-bar {
    padding-top: 50px;
  }

  .goodsList {
    width: 100%;
    height: 90vh;
    padding: 30px;

    .goodsOne {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      img {
        width: 130px;
        height: 130px;
        background: #FFFFFF;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        border: 2px solid #D5D5D5;
        margin-right: 20px;
      }

      .goodsRight {
        .rightTop {
          display: flex;
          align-items: flex-start;

          .jiang {
            padding: 6px 10px;
            background: #FC3E5A;
            border-radius: 10px 10px 10px 10px;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }

          .jiangTitle {
            width: 430px;
            /* 宽度自行调整 */
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 12px;
            font-size: 30px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
        }

        .fenshu {
          margin-top: 40px;
          font-size: 26px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>